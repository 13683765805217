@import "~@/styles/variables/variables.scss";










































































::v-deep {
	.el-form {
		margin-top: 20px;
	}
}
